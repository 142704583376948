<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看场景身份"
    :append-to-body="true"
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <el-divider></el-divider>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          @click="handleAdd"
        >新增</el-button>
        <!-- <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          :disabled="multipleSelection.length==0"
          @click="handleBatchDelete"
        >删除选中项</el-button> -->
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          @click="handleBack"
        >返回</el-button>
      </el-row>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="sceneN" label="场景值" width="180"></el-table-column>
        <el-table-column prop="popedomN" label="身份" width="180"></el-table-column>
        <el-table-column prop="sex" label="性别" width="180">
          <template slot-scope="{row}">
            <span v-if="row.sex == null || row.sex == ''">全部</span>
            <span v-else-if="row.sex == 1">男</span>
            <span v-else-if="row.sex == 2">女</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <deviceSceneRelation-detail
        v-if="showModal"
        :businessKey="businessKey"
        :companyId = "companyId"
        :sceneId = "sceneId"
        :title="modalTitle"
        @close="onDetailModalClose"
      ></deviceSceneRelation-detail>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import DeviceSceneRelationDetail from "./sceneRelation-detail";
import deviceSceneRelationApi from "@/api/base/deviceSceneRelation";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["companyId", "sceneId"],
  data() {
    var self = this;
    return {
      queryModel: {
        popedom: "",
        companyId: "",
        sceneId: "",
        sex: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      showDialog: true
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("companyId", self.companyId);
      formData.append("sceneId", self.sceneId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      deviceSceneRelationApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          deviceSceneRelationApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deviceSceneRelationApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleBack(){
      this.$emit("close", false);
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "deviceSceneRelation-detail": DeviceSceneRelationDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>