<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看场景值"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <el-divider></el-divider>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="序号" index="1" width="50"></el-table-column>
        <el-table-column prop="sceneN" label="场景值" width="180"></el-table-column>
        <el-table-column prop="popedomN" label="身份" width="350"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <sceneRelation-content
        v-if="showModal"
        :companyId="companyId"
        :sceneId="sceneId"
        :title="modalTitle"
        @close="onDetailModalClose"
      ></sceneRelation-content>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import SceneRelationContent from "./sceneRelation-content";
import deviceSceneRelationApi from "@/api/base/deviceSceneRelation";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["companyId"],
  data() {
    var self = this;
    return {
      queryModel: {
        popedom: "",
        companyId: "",
        sceneId: "",
        sex: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      sceneId: "",
      showDialog: true
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("companyId", self.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      deviceSceneRelationApi
        .sceneList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.companyId = this.companyId;
      this.sceneId = record.sceneId;
      this.showModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "sceneRelation-content": SceneRelationContent
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>