<template>
  <div style="padding-left:5px;">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/deviceSceneRelation">校园场景值管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="单位名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="sortNo" label="序号" width="120"></el-table-column>
      <el-table-column prop="name" label="单位名称" width="230"></el-table-column>
      <el-table-column prop="shortName" label="简称" width="120"></el-table-column>
      <el-table-column prop="parentName" label="上级单位" width="230"></el-table-column>
      <el-table-column label="性质" width="80">
        <template>学校</template>
      </el-table-column>
      <el-table-column prop="remark" sort-by="remark_" label="备注"></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{row}">
          <el-row>
            <el-col>
              <el-link type="primary" :underline="false" @click="handleOpenScene(row)">查看场景值</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <sceneRelation-list v-if="showModal" :companyId="businessKey" @close="onDetailModalClose"></sceneRelation-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import sceneRelationList from "./sceneRelation-list";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import QRCode from "qrcodejs2";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: 'BaseSceneCompanyList',
  data() {
    var self = this;
    return {
      activeName: "list",
      queryModel: {
        name: "",
        parentId: "",
        subordinate: false
      },
      loading: false,
      tableData: [],
      treeData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      showDeviceModal: false,
      companyId: "",
      qrCode: "",
      companyResult: [],
      tableHeight: 400,
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      batchImportVisible: false,
      batchImportFileList: [],
      uploadUrlXls: Constant.serverUrl + "/base/companyInfo/importXls",
      uploadXlsData: {
        subFolder: "company",
        companyId: ""
      },
      headers: {
        Authorization: getToken()
      },
      xlsLoading: false
    };
  },
  created() {
    var self = this;

    // companyInfoApi.treeList().then(function(response) {
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     self.companyResult = jsonData.data;
    //   }
    // });

    this.changePage(1);
    //this.loadTree();

    setTimeout(()=>{
      //45为分页栏的高度
      //页面高度-列表上面的高度-分页栏高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    },1000);
  },
  methods: {
    // loadTree() {
    //   var formData = new FormData();

    //   companyInfoApi.loadChildren(formData).then(resp => {
    //     var jsonData = resp.data;

    //     if (jsonData.result) {
    //       this.treeData = jsonData.data;
    //     } else {
    //       this.$message.error(jsonData.message + "");
    //     }
    //   });
    // },
    // loadChildren(tree, treeNode, resolve) {
    //   console.log(tree);

    //   var formData = new FormData();
    //   formData.append("parentId", tree.id);

    //   companyInfoApi.loadChildren(formData).then(resp => {
    //     var jsonData = resp.data;

    //     if (jsonData.result) {
    //       resolve(jsonData.data);
    //     } else {
    //       this.$message.error(jsonData.message + "");
    //     }
    //   });
    // },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("parentId", self.queryModel.parentId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("type", "2");
      formData.append("needDTO", false);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      companyInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleOpenScene(record) {
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          companyInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        companyInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showDeviceModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {},
  components: {
    "sceneRelation-list": sceneRelationList
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>