
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="场景值" prop="sceneId">
          <el-select v-model="formModel.sceneId" filterable disabled placeholder="请选择" style="width:80%">
            <el-option
              v-for="result in deviceAddressData"
              :key="result.id"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="popedom">
          <el-select v-model="formModel.popedom" placeholder="请选择" style="width:80%">
            <el-option
              v-for="popedom in popedomList"
              :key="popedom.value"
              :label="popedom.name"
              :value="popedom.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="formModel.sex">
            <el-radio label>全部</el-radio>
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import deviceSceneRelationApi from "@/api/base/deviceSceneRelation";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["businessKey", "title", "companyId", "sceneId"],
  data() {
    return {
      formModel: {
        sex: ""
      },
      ruleValidate: {
        popedom: [{ required: true, message: "身份不能为空", trigger: "blur" }],
        sceneId: [
          { required: true, message: "场景值不能为空", trigger: "blur" }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      popedomList: [],
      deviceAddressData: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            //self.formModel.companyId = self.businessKey;

            if (id == null || id.length == 0) {
              return deviceSceneRelationApi.add(self.formModel);
            } else {
              return deviceSceneRelationApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    initialization() {
      var formData = new FormData();
      formData.append("catalogName", "用户身份");
      dataDictionaryApi.findByCatalogName(formData).then(response => {
        var jsonData = response.data;
        this.popedomList = jsonData.data;
      });

      var formData2 = new FormData();
      formData2.append("catalogName", "设备场景值");
      dataDictionaryApi.findByCatalogName(formData2).then(response => {
        var jsonData = response.data;
        this.deviceAddressData = jsonData.data;
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return deviceSceneRelationApi.create();
      } else {
        return deviceSceneRelationApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.initialization();
          self.formModel = jsonData.data;
          self.formModel.companyId = self.companyId;
          self.formModel.sceneId = self.sceneId;

          if (self.formModel.sex == null) {
            self.formModel.sex = "";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>